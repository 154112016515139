.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* ::-webkit-scrollbar {
  display: none!important;*/

.MuiDataGrid-virtualScrollerContent{
  background: #fff !important;
}

.makeStyles-root-1 {
  max-height: 100vh !important;

}

.makeStyles-typo-68 {
  color: #000000 !important;
  font-weight: 700 !important;
}

.MuiDataGrid-columnHeaders{
  /* background: #0b5aa4 !important; */
  color: #fff;
}

.MuiButton-containedSecondary {
  /* font-family: 'SF UI Text' !important; */
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 33px !important;
  color: #474747 !important;
  color: #000000 !important;
  background-color: #968e9100 !important;
}

.MuiButton-contained {
  box-shadow: none !important;
  /* background-color: #e0e0e000 !important; */
}

.pending {
  color: #FFA134;
}

.invoice {
  color: #FFA134;
}

.makeStyles-contentShift-3 {
  background: #EFF7FF;
  border-radius: 30px;
  padding-left: 24% !important;
  border: 20px solid #0B5AA4;
}

.makeStyles-typo-492 {
  color: #262A41 !important
}

.makeStyles-widgetWrapper-494 {
  box-shadow: 40px 4px 4px 0.2px rgba(180, 180, 180, 0.25);
  border-radius: 20px;
}

.MuiTypography-colorTextSecondary {
  /* font-family: 'SF UI Text' !important; */
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 26px !important;
  line-height: 31px !important;
  color: #2C2B2B !important;

}

.w-70 {
  width: 70% !important;
}

.revenue {
  /* font-family: 'SF UI Text'!important; */
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 26px !important;
  line-height: 31px !important;
  color: #2C2B2B !important;
}

.makeStyles-mainChartLegentElement-63 {
  /* font-family: 'Roboto'!important; */
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 1px !important;
  font-variant: small-caps !important;
  color: #3C3C3C !important;
}

.sidebar__menu {
  margin-top: 220px;
}

.makeStyles-toolbar-8 {
  display: none !important;
}

.search-form {
  display: flex;
  margin-left: 80px !important;
  padding-left: 60px;
}

.search-input {
  color: #6C6C6C;
  font-weight: 500;
  margin-right: 10px;
  width: 460px;
  padding: 10px;
  font-size: 12px;
  border: 1px solid #ccc;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(137, 137, 137, 0.25);
  border-radius: 20px 0px 0px 20px;
}

.dropdown {
  color: #6C6C6C;
  font-weight: 500;
  width: 142px;
  margin-right: 10px;
  padding: 10px;
  font-size: 12px;
  border: 1px solid #ccc;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(137, 137, 137, 0.25);
  border-radius: 0px 20px 20px 0px;
}

.notify {

  position: absolute;
  /* font-size: 25px !important; */
  right: 10px;
  top: 10px;
  box-shadow: 0px 2px 2px rgba(137, 137, 137, 0.25);
  border-radius: 50%;
  padding: 14px;
  /* mix-blend-mode: hue; */
}

.MuiTypography-h5 {
  font-size: 18px !important;
  /* font-family: 'SF UI Text' !important; */
  font-style: normal !important;
  font-weight: 600 !important;
  /* font-size: 30px; */
  line-height: 36px !important;
  color: #0B5AA4;
}


.makeStyles-totalValue-85 {
  font-size: 25px !important;

}

.demo {
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 36px !important;
  color: #FFA134 !important;
}

.chartjs-size-monitor {
  background: #FFFFFF !important;
  box-shadow: 4px 4px 4px 0.2px rgba(180, 180, 180, 0.25) !important;
  border-radius: 20px !important;
}

.d-flexed {
  margin-top: 20px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.datefilter {
  margin: 6px;
  padding: 5px;
  background: #FFFFFF !important;
  border: 1px solid #CDCDCD !important;
  box-shadow: 0px 1px 1px rgba(83, 83, 83, 0.25) !important;
  border-radius: 10px !important;
  margin-top:-4px;
}
.datefilter >div{
  width: 400px;display: flex;
  justify-content: space-between;
}
.datefilter >div input{
border-bottom: 2px solid #111;
border-top: 2px solid transparent;
border-right: 2px solid transparent;
border-left: 2px solid transparent;

}
.datefilter >div button{
  border:  2px solid transparent;
  padding: 2px 10px ;

  border-radius: 5px;
}

.chart {
  background: #FFFFFF !important;
  box-shadow: 4px 4px 4px 0.2px rgba(180, 180, 180, 0.25) !important;
  border-radius: 20px !important;
  Padding-top: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.MuiSelect-icon {
  display: none;
}

.MuiSelect-select.MuiSelect-select {
  display: none;
}

.makeStyles-widgetWrapper-70 {
  background: #FFFFFF !important;
  box-shadow: 4px 4px 4px 0.2px rgba(180, 180, 180, 0.25) !important;
  border-radius: 40px !important;
}

.makeStyles-moreButton-78 {
  display: none !important;
}

/* .MuiInput-root{
  display: none !important;
} */
.makeStyles-paper-77 {
  border-radius: 20px !important;
}

.notification {
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(86, 86, 86, 0.25);
  border-radius: 20px;
  padding: 10px;
  margin-right: 20px;


}

.sidebar__menu__item__icon {
  margin-right: 9px;
  padding-top: 5px;
}

.makeStyles-fakeToolbar-4 {
  display: none;
}

.makeStyles-pageTitleContainer-67 {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}

.MuiTypography-h1 {
  font-size: 25px !important;
}

.makeStyles-widgetBody-75 {
  padding-left: 14px !important;
  padding-right: 14px !important;
  padding-bottom: 0px !important;
}

.MuiGrid-spacing-xs-4>.MuiGrid-item {
  padding: 7px !important;
}

.makeStyles-widgetHeader-72 {
  padding-top: 5px !important;
  padding-left: 15px !important;
  padding-bottom: 0px !important;
}

.makeStyles-content-2 {
  padding-top: 10px !important;

}

.makeStyles-title-81 {
  margin-bottom: 0px !important;
}

.makeStyles-totalValueContainer-84 {
  align-items: center !important;
  transform: translateY(-10px) !important;
}

.makeStyles-bodyWidgetOverflow-89 {
  overflow: initial !important;
  /* margin-bottom: -30px !important; */
}

/* .h2{
  margin-top: 7px !important;
  margin-bottom: 7px !important;
} */



/* ------------- */


#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.content {
  position: fixed;
  top: 0;
  background: rgba(15, 15, 15, 0.5);
  color: #f1f1f1;
  width: 100%;
  height: 100vh;
  padding: 40px;
}

.loginpageLeft,
.loginpageLeft p {
  text-align: left;
  align-items: left;
  /* padding: 20px; */

}

.loginpageLeft p {
  color: #fff;
  line-height: 28px;
}

.loginpageRight {
  text-align: center;
  align-items: right;
  justify-content: right;
  padding-left: 17% !important;
  padding-top: 7%;
}

.loginformDiv {
  /* padding-top: 30px; */
  box-shadow: 5px 2px 3px 3px #5a5a5a;
  top: 20%;
  position: relative;
  border-radius: 8%;
}

.loginformDiv h3 {
  font-size: 28px;
  margin-left: 50px;
  font-weight: 700;
}

/* .loginformDiv{
  height: 500px;
  width: 400px;
  
} */
.loginformDiv input {
  width: 65% !important;
  height: 40px;
  border-radius: 5px;
  color: #000000;
  font-weight: 600;
  margin-top: 11px;
  border: 2px solid transparent;
  box-shadow: 0 0 10px 0 rgb(54 54 54 / 92%);
  ;
  padding-left: 10px;
  margin-left: 2%;
}

.login_text {
  font-size: 20px;
  /*text-align: initial;*/
  margin-left: 70px;
  color: #fff;
}

.login-ask {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: auto;
  font-size: 12px;
  cursor: pointer;
  margin-left: 22%;
}

.login-ask p {
  font-size: 14px !important;
  color: #fff;
  margin-top: 5px;
}

.loginBottomText {
  font-size: 50px;
  margin-top: -50px;
  color: rgb(199, 199, 2);
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}

.loginflexed {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .loginformDiv {
    text-align: center;
  }

  .logo {
    text-align: center;
  }

  .loginBottomText {
    font-size: 25px;
    margin-top: -25%;

  }

  .loginpageRight {
    padding-left: 1px !important;
  }

  /* .loginformDiv{
    padding-top: 1px;
    margin-left: -70px;
  } */
  .loginpageLeft p {
    text-align: center;
    line-height: 20px;
  }

  .loginflexed {
    display: block;
  }
}

.loginbgimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.buttn {
  /* background-color:#3387d6!important; */
  /* border-color: rgba(248, 58, 58, 0.884)!important; */
  width: 30%;
  border-radius: 8px;
  color: #fff !important;
  font-weight: 700;
  border-bottom: 1px solid #fff !important;
  border-left: transparent;
  border-top: transparent;
  border-right: transparent;
  /* border: 2px solid transparent; */
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  margin-left: 65px !important;
}

.scroller {
  position: absolute;
  height: 95vh;
  top: 10px;
  left: 22.5%;
  width: 75%;
  padding-right: 1%;
}

.scrollerfull {
  position: absolute;
  height: 95vh;
  width: 97%;
  top: 10px;
  left: 1%;

}
.text-capitalize{
  text-transform: capitalize !important;
}
.dashboard_card_images{
  position: absolute;
  width: 169px !important;
  height: 100px;
  bottom: 0px;
  right: 0;
}

/* .z-2
{
z-index: -9;
} */


/* restaurant responive Design  */
@media screen and (max-width: 767px) {
  .scrollerfull .TOGGLEicon {
    left: 0% !important;
z-index: 999999 !important;
  }
  .scroller .TOGGLEicon {
    left: -30% !important;
z-index: 999999 !important;
  }
  #getReport{
    margin-top: 5px;
  }
.dashboard_card_images{
  position: absolute;
  width: 119px !important;
  height: 67px;
  bottom: 0px;
  right: 0;
}
.dashboard_card_number_counter{
  font-size: 28px !important;
  width: 230px !important;
}
.notify{
  position: absolute;
  /* font-size: 25px !important; */
  right: 0 !important;
  top: 0 !important; 
}
.search-inut{
  position: relative;
  margin-left: -83px;
}

.datefilter > div{
  width: 240px;
  display: block;
}
.datefilter > div input{
margin-bottom: 6px;
width: 100%;
}


}



/*collection report media wuerry   ====  report media wuery for mobile  */   

@media screen and (max-width: 767px){
  .collection_Table{
    margin-top: 63px !important;
    margin-bottom: 20px !important;
  }
  .collection_brnGrp  {
    position: absolute;
   
    margin-top: 125px !important;
  }
 
}

.dashboard_card_number_counter{
  font-size: 36px ;
  width: 230px !important;
}

.TOGGLEicon {

  left: 0px;
  z-index: 99;
  position: absolute;
  border-radius: 50%;
  color: "gray";
  /*background:#ffffff; */
  cursor: pointer;
  padding: 13px;
  box-shadow: 0px 2px 2px rgba(86, 86, 86, 0.25);
  top: -6px;

}

.popupbg {
  position: fixed;
  top: 0;
  z-index: 111;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-main {
  position: fixed;
  background-color: #305f8b;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #333;
  overflow-y: scroll;
  padding: 1rem;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background: transparent;
  color: #fff;

}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;

}



.makeStyles-pageTitleContainer-34 {
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}

.dashboardcard {

  MARGIN-LEFT: 1PX !important;

  margin: 11px !important;
  margin-top: -20px !important;

}

.dashboardsecondcard {
  margin-top: -40px !important;
}

.dashboardcard>div {
  height: 137px !important;
  width: 195px !important;

}

.dashboardsecondcard .dashboardcardimage {
  width: 110px;
  height: 70px;
  float: right;
  position: relative;
  top: -120px;
}

.dashboardsecondcard>div {
  margin-left: -30px;
  width: 356px;
}

.dashboardsecondcard>div>div {
  height: 130px !important;
  overflow: hidden;
  margin-left: 29px !important;
  width: 235px !important;

}

.dashboardcard>div h5 {
  font-size: 14px !important;
  line-height: 15px !important;
}

.dashboardcard::-webkit-scrollbar {
  display: none;
}

.dashboardsecondcard>div h5 {
  font-size: 14px !important;
  line-height: 15px !important;
}

.dashboardsecondcard::-webkit-scrollbar {
  display: none;
}

.popup-fullscreen::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dashboardcard {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.popup-fullscreen {
  position: fixed;
  background-color: #f4f4f5;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #333;
  overflow-y: scroll;
  /* padding: 1rem; */
}

.popup-fullscreen .MuiGrid-root {
  padding-left: 5px !important;
}

.poinvoiceform {
  display: flex;
  justify-content: space-between;
  padding: 18px;
  margin: auto;
  width: 100%;
  background: #fff;
}

.poinvoiceform input {
  margin-left: 20px !important;


}

.pendinrfq-details h4 {
  color: #fff;
}

.pendinrfq-details p {
  background: #fff;
  color: #111;
}


.balacepoupformmain {
  height: 90vh;
  width: 100%;
}

.balacepoupformleft {
  width: 30%;
  height: 100%;
}

.left-blankspace {
  height: 130px !important;
  background: rgb(252, 252, 252);
  width: 98%;
}

.balacepoupformleft ul {
  height: 120px;
  overflow: scroll;
  padding: 1px;
}

.balacepoupformleft li {
  list-style: none;


}

.balacepoupformright {
  width: 69%;
  height: 100%;

}

.balacepoupformleft .checkboxes-d-flex {
  display: flex;

}

.d-flex {
  display: flex;
  justify-content: space-between;
}

.mt-2 {
  margin-top: 10px !important;
}

.form-heading {
  position: absolute;
  background: #000000;
  color: #fff;
  left: 0;
  width: auto;
  padding-left: 10px;
  padding: 5px;
  border: 2px solid #333;
  box-shadow: 0 0 20px 0 rgba(145, 142, 142, 0.9);
}

.crossicon {
  color: rgb(17, 17, 17);
  /* float: right; */
  background: rgb(0, 0, 0);
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 0px;
  z-index: 111 !important;

}

.reportsSubmenu {
  position: absolute;
  background: #0b5aa4;
  padding: 20px;
  top: 30%;
  left: 100%;
  width: 200px;
}

.reportsSubmenu li {
  list-style: none;
}

.reportsSubmenu li a {
  color: #fff;
  text-decoration: none;
}

.d-none {
  display: none;
}

#selectFROMdropdown {
  margin-top: 12px;
  width: 97%;
  border: none;
}

#selectTOdropdown {
  margin-top: 20px;
  width: 97%;
  border: none;
}

.reportsselectedAcccounts {
  width: 97%;
  background-color: rgb(255, 255, 255);
  /* background: #fff; */

}

.reportheight {
  height: 120px !important;
}

.colectionreportform {
  height: 20%;
  width: 100%;
  display: flex;
}

.colectionreportform table {
  width: 43%;
}

.collectionformcheckboxdiv {
  margin: 4px;
  height: 129px !important;
  padding: 1px;
  width: 95px;
}

.collectionreportbtns button {
  display: block;
  width: 200px;
  line-height: 21px;
}

.d-block {
  display: block;
}

.makeStyles-row1Card-156 {
  /* height: 100% !important; */
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 15px !important;
    border: 1px solid #ffffff;
}

.MuiGrid-container {
  padding-bottom: 20px;
  justify-content: space-around;
}

.Graphdata {
  background: #fff;
  border: 1px solid #11111126;
  border-radius: 10px;
  box-shadow: 5px 10px #f7f5ff;
  margin-left: 0px !important;
  width: calc(100%) !important;
  margin-top: 40px !important;
}

/*CODE BY VIBHAV*/

.collection_Table td {

  border: 1px solid #333;

}

.PartyStockLedger_2 p {

  font-size: 12px;

}


.ageing_2grid select {

  float: right;

  border: none;

  padding: 2px 8px;

  box-shadow: 0 0 10 0 rgb(158, 154, 154);

}


.ageing_buttonsGrp {

  padding: 5px 10px;

}




.collection_brnGrp button {

  width: 130px;

  border: none;

  height: 30px;

  font-size: 13px;

  text-transform: uppercase;

  box-shadow: 0 0 10px 0 rgba(119, 117, 117, 0.9);

}


.PartyStockLedger_2grid select,
.PartyStockLedger_2grid>div {

  float: right;

  border: none;

  padding: 2px 8px;

  box-shadow: 0 0 10 0 rgb(158, 154, 154);

}



.scrollerDiv{
  height: 17vh;
  width: 100%;
  margin: auto;
  border: 3px solid #0b5aa4;
  text-align: start;
  background: #fff;
  color: #111;
  margin-top: 10px;
}

/* hide scrollbar but allow scrolling */

.scrollerDiv {

  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */

  scrollbar-width: none;
  /* for Firefox */

  overflow-y: scroll;

}


.scrollerDiv::-webkit-scrollbar {

  display: none;
  /* for Chrome, Safari, and Opera */

}

.partystock_main {

  display: flex;

}

.trailBalance_main {

  display: flex;

}

.AgeingAnalysis_main {

  display: flex;

}

.AgeingAnalysis_main .balacepoupformleft {

  font-size: 12px !important;

}

.Gstr_main {

  display: flex;

}

.AllwareHouseStock_main {

  display: flex;

}

.general_ledger {

  display: flex;

}


@media screen and (max-width: 767px) {

  .partystock_main {

    display: block !important;

  }

  .partystock_main .balacepoupformleft,
  .partystock_main .balacepoupformright {

    width: 100% !important;

  }

  .partystock_main .balacepoupformright {

    margin-top: -200px;

  }


  /* Trail Balance */

  .trailBalance_main {

    display: block;

    margin-left: 10px;

  }

  .trailBalance_main .balacepoupformleft,
  .trailBalance_main .balacepoupformright {

    width: 100% !important;

  }

  .trailBalance_main .balacepoupformright {

    margin-top: -50px;

  }

  /* Ageing Analysis  */




  .AgeingAnalysis_main {

    display: block;

    margin-left: 10px;

    font-size: 12px !important;

  }

  .AgeingAnalysis_main .balacepoupformleft,
  .AgeingAnalysis_main .balacepoupformright {

    width: 100% !important;

  }

  .AgeingAnalysis_main .balacepoupformright {

    margin-top: -200px;

  }


  /* GSTR */

  .Gstr_main {
    display: block;

    margin-left: 10px;

    font-size: 12px !important;

  }

  .Gstr_main .balacepoupformleft,
  .Gstr_main .balacepoupformright {

    width: 100% !important;

  }

  .Gstr_main .balacepoupformright {

    margin-top: -80px;

  }

  /* AllwareHouseStock_main  */
  .AllwareHouseStock_main {

    display: block;

    margin-left: 10px;

  }

  .AllwareHouseStock_main .balacepoupformleft,
  .AllwareHouseStock_main .balacepoupformright {

    width: 100% !important;

  }

  .AllwareHouseStock_main .balacepoupformright {

    margin-top: -200px;

  }


  /* AllwareHouseStock_main  */

  .general_ledger {

    display: block;

    margin-left: 10px;

  }

  .general_ledger .balacepoupformleft,
  .general_ledger .balacepoupformright {

    width: 100% !important;

  }

  .general_ledger .balacepoupformright {

    margin-top: -200px;
  }

}

.report_reportname{
  font-weight: 700;
  font-size: 16px ;
  margin-left: 30px;
  font-style: italic;
}
.allfont .row{
  border: 1px solid #fff;
  font-size: 12px ;
  margin-bottom: 1px !important;
  padding: 2px;
  background:#e7e7ec;
  color: #111;
  }
  .groupSelection{
    height: 25vh;
    width: 100%;
    margin: auto;
    border: 1px solid rgb(252, 252, 252);
    text-align: start;
    overflow-y: scroll ;
    margin-top: 10px;
  }
  
  .groupSelection::before{
    content: "Group Selection";
    position: absolute;
  background: #e7e7ec;
  color: #0b5aa4;
  margin-top: -8px;
  margin-left: 5px;
  }

  .gstr_ButtonGroup{
    height: 95vh !important;
    overflow:  scroll ;
  }
  
.gstr_ButtonGroup Button{
  margin: 1px !important;
  font-size: 10px !important ;
  width: 96% !important;
  text-align: start !important
}

.table > :not(caption) > * > * {
  padding: 0px !important;
}

.Commonloader {
  left: 0;
  top: 0;
    bottom: 0;
  width: 100vw!important;
    height:1000vw!important;
    background-color: #2c2c2cd9 !important;
    position: absolute !important;
    text-align: center !important;
    z-index: 9999999;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-image: url('./images/pos/loader.gif');

}

.orderdata{
  width: calc(100%) !important;
}

.order_mangmnt_dashbord_CardH{
  height: 130px !important;
}
.order_mangmnt_dashbord_Card2H{
  height: 200px !important;
}

@media screen and (max-width: 767px) {
  .ForMedia_restorant_1stContainer{
    margin-top: -63px !important;
  }
  .ForMedia_restorant_1stContainer h1{
    font-size: 20px !important;
  }
  .ForMedia_restorant_1stContainer button{
    font-size: 10px;
  }
  .search-form {
    margin-left: 44px !important;
    transform: scale(0.7);
}
.restorant_mobile_3grid{
  margin: 0px !important;
}

}
.debitor_Card:hover .debitor_balance_text{
text-overflow: initial;
}

.css-17jjc08-MuiDataGrid-footerContainer{
  background: #fff !important;
}

.paraLogout{
  width: 77%;
  position: fixed;
    display: flex;
    padding: 11px;
    justify-content: space-between;
    text-align: center;
    background: #e5ad11;
    z-index: 999;
    height: 49px;
    border-radius: 6px;
    box-shadow: 0 0 20px 0 rgb(183 164 164 / 97%);
}

/* width */
::-webkit-scrollbar {
  width: var(--scroller-width);
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--thumb-bg-color);
}




.logoutmsg{

  color: white;
  font-weight: 700;
  font-size: 17px;
}
.paraLogout button{
  padding: 2px 20px;
  border:  2px solid #fff;
  background: #fff;
  color: #111;
  border-radius: 3px;
}
.loaderbtnApply{
  height: 20px;
  transform: scale(4.5);
}

.custom-alert {
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
    padding: 5px;
    margin-left: 45px;
    /* position: absolute; */
    width: 91%;
    height: 40px;
    margin-bottom: 10px;
    color: #fff;
    font-size: 18px;
    letter-spacing: 1px;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(160, 154, 154, 0.9);
}

.custom-alert.show {
  display: block;
  opacity: 1;
}

.custom-alert.hide {
  display: none;
  opacity: 0;
}